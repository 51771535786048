#top-bar {
	background-color: $blue;
	.container {
		background-color: $blue;
	}
	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
		font-family: $font_1, $font_2, $font_3, $font_4, $font_5, $font_6;
		li {
			float: left;
			font-size: .75rem;
			text-transform: uppercase;
			a {
				padding: 0 15px;
				line-height: 30px;
				color: $white;
				text-decoration: none;
				opacity: .5;
				&:hover {
					opacity: 1;
				}
			}
		}
	}
}

#header {
  background-color: $header_background_color;
  #top {
    .container {
      padding: 30px;
      background-color: $header_color;
    }
    #logo {
      float: left;
      .site-title {
        font-size: 36px;
        float: left;
        line-height: 1;
        margin-bottom: 12px;
        margin-right: 20px;
        a {
          color: #FFF;
          display: block;
          text-decoration: none;
        }
      }
    }
    #top-ad {
      float: right;
      max-width: 728px;
      .adsbygoogle {
        width: 320px;
        height: 100px;
      }
    }
  }
}
