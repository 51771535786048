//colors
$white: #fff;
$black: #000;

$primary_color: #4183c4;
$font_color: #333;

$body_background_color: #F5F5F5;

$header_color: #4183c4;
$header_background_color: #4D8BC7;

$navbar_color: #2E79C2;
$navbar_background_color: #4183c4;

$red: #F44336;
$red_lighten_5: #FFEBEE;
$red_lighten_4: #FFCDD2;
$red_lighten_3: #EF9A9A;
$red_lighten_2: #E57373;
$red_lighten_1: #EF5350;
$red_darken_1: #E53935;
$red_darken_2: #D32F2F;
$red_darken_3: #C62828;
$red_darken_4: #B71C1C;
$red_accent_1: #FF8A80;
$red_accent_2: #FF5252;
$red_accent_3: #FF1744;
$red_accent_4: #D50000;

$blue: #2196F3;
$blue_lighten_5: #E3F2FD;
$blue_lighten_4: #BBDEFB;
$blue_lighten_3: #90CAF9;
$blue_lighten_2: #64B5F6;
$blue_lighten_1: #42A5F5;
$blue_darken_1: #1E88E5;
$blue_darken_2: #2B85E7;
$blue_darken_3: #1565C0;
$blue_darken_4: #0D47A1;
$blue_accent_1: #82B1FF;
$blue_accent_2: #448AFF;
$blue_accent_3: #2979FF;
$blue_accent_4: #2962FF;

$grey: #9e9e9e;
$grey_lighten_5: #fafafa;
$grey_lighten_4: #f5f5f5;
$grey_lighten_3: #eeeeee;
$grey_lighten_2: #e0e0e0;
$grey_lighten_1: #bdbdbd;
$grey_darken_1: #757575;
$grey_darken_2: #616161;
$grey_darken_3: #424242;
$grey_darken_4: #212121;

$color_rhino_approx: #2c465f;
$color_saffron_approx: #f7c928;
$black_15: rgba(0,0,0,0.15);
$color_gallery_approx: #eee;
$alabaster: #fafafa;
