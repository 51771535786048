#nav {
	background-color: $navbar_background_color;
	.container {
		padding: 0;
	}
	#mobile-nav-trigger {
		display: none;
	}
	ul.nav {
		display: block;
		background-color: $navbar_color;
		margin: 0;
		padding: 0;
		list-style-type: none;
		li {
			display: inline-block;
			float: left;
			font-family: $font_1, $font_2, $font_3, $font_4, $font_5, $font_6;
			font-size: .8125rem;
			a {
				display: inline-block;
				line-height: 2.8125rem;
				padding: 0 15px;
				text-decoration: none;
				text-transform: uppercase;
				color: $white;
				background: transparent;
				&:hover {
					color: $black;
					background: $white;
				}
				i.mdi {
					font-size: 1.125rem;
				}
			}
			&.nav-current a {
				color: $white;
				background-color: #236DB4;
				&:hover {
					color: $black;
					background: $white;
				}
			}
		}
		&.active {
			display: block;
		}
	}
}
