.button {
	@extend %extend_2;
	&:hover {
		@extend %extend_3;
	}
	&.button-social {
		width: 36px;
		height: 36px;
		padding: 0;
		line-height: 36px;
		color: $white;
		text-align: center;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 50%;
		//Instead of the line below you could use @include background-clip($clip)
		background-clip: padding-box;
		&:hover {
			color: $white;
			background-color: $black;
		}
		i {
			line-height: 36px;
		}
	}
	&.button-twitter {
		color: $white;
		text-decoration: none;
		&:hover {
			background-color: $black;
			color: $white;
			text-decoration: none;
		}
	}
	&.button-facebook {
		color: $white;
		text-decoration: none;
		&:hover {
			background-color: $black;
			color: $white;
			text-decoration: none;
		}
	}
	&.button-google-plus {
		color: $white;
		text-decoration: none;
		&:hover {
			color: $white;
			text-decoration: none;
		}
	}
	&.button-linkedin {
		color: $white;
		text-decoration: none;
		&:hover {
			color: $white;
			text-decoration: none;
		}
	}
	&.button-pinterest {
		color: $white;
		text-decoration: none;
		&:hover {
			color: $white;
			text-decoration: none;
		}
	}
	&.button-youtube {
		color: $white;
		text-decoration: none;
		&:hover {
			color: $white;
			text-decoration: none;
		}
	}
	&.button-vimeo {
		color: $white;
		text-decoration: none;
		&:hover {
			color: $white;
			text-decoration: none;
		}
	}
	&.button-vine {
		color: $white;
		text-decoration: none;
		&:hover {
			color: $white;
			text-decoration: none;
		}
	}
	&.button-flickr {
		color: $white;
		text-decoration: none;
		&:hover {
			background-color: $black;
			color: $white;
			text-decoration: none;
		}
	}
	&.button-instagram {
		color: $white;
		text-decoration: none;
		&:hover {
			background-color: $black;
			color: $white;
			text-decoration: none;
		}
	}
	&.button-tumblr {
		color: $white;
		text-decoration: none;
		&:hover {
			background-color: $black;
			color: $white;
			text-decoration: none;
		}
	}
	&.button-dribbble {
		color: $white;
		text-decoration: none;
		&:hover {
			background-color: $black;
			color: $white;
			text-decoration: none;
		}
	}
	&.button-yahoo {
		color: $white;
		text-decoration: none;
		&:hover {
			background-color: $black;
			color: $white;
			text-decoration: none;
		}
	}
	&.button-github {
		color: $white;
		text-decoration: none;
		&:hover {
			background-color: $black;
			color: $white;
			text-decoration: none;
		}
	}
	&.button-rss {
		color: $white;
		text-decoration: none;
		&:hover {
			background-color: $black;
			color: $white;
			text-decoration: none;
		}
	}
	&:not(.button-social) > i.mdi:first-child {
		margin-right: 5px;
	}
}
button {
	@extend %extend_2;
	&:hover {
		@extend %extend_3;
	}
}
input[type=button] {
	@extend %extend_2;
	&:hover {
		@extend %extend_3;
	}
}
input[type=reset] {
	@extend %extend_2;
	&:hover {
		@extend %extend_3;
	}
}
input[type=submit] {
	@extend %extend_2;
	&:hover {
		@extend %extend_3;
	}
}
