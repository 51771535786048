//fonts
$font_0: weather;
$font_1: Montserrat;
$font_2: HelveticaNeue;
$font_3: Helvetica Neue;
$font_4: Helvetica;
$font_5: Arial;
$font_6: sans-serif;
$font_7: Source Sans Pro;
$font_8: Georgia;
$font_9: serif;

//@extend-elements
//original selectors
//h1, h2, h3, h4, h5, h6
%extend_headline {
  font-family: $font_1, $font_2, $font_3, $font_4, $font_5, $font_6;
  font-weight: bold;
  line-height: 1.2;
  color: $black;
}

//original selectors
//.button, button, input[type=button], input[type=reset], input[type=submit]
%extend_2 {
  background-color: $blue_lighten_1;
  color: $white;
  text-decoration: none;
  display: inline-block;
  padding: 7px 12px;
  border: 0;
  //Instead of the line below you could use @include background-clip($clip)
  background-clip: padding-box;
}

//original selectors
//.button:hover, button:hover, input[type=button]:hover, input[type=reset]:hover, input[type=submit]:hover
%extend_3 {
  background-color: $blue_darken_1;
  color: $white;
  text-decoration: none;
}

//original selectors
//input:not([type=radio]):not([type=checkbox]):not([type=button]):not([type=reset]):not([type=submit]), textarea, select
%extend_4 {
  padding: 7px 12px;
  border: 1px solid $primary_color;
  background-clip: padding-box;
}

//original selectors
//table tr th, table tr td
%extend_5 {
  text-align: left;
  padding: .5rem 1rem;
  border-width: 0 0 1px;
  border-bottom: 1px solid $primary_color;
}


html {
  margin: 0;
  font-size: 16px;
}
body {
  margin: 0 auto;
  padding: 0;
  background-color: $body_background_color;
  font-family: $font_7, $font_2, $font_3, $font_4, $font_5, $font_6;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.4;
  letter-spacing: -0.03em;
  color: $font_color;
}

a {
  color: $blue_lighten_1;
  &:hover {
    color: $blue;
    text-decoration: none;
  }
}

h1 {
  @extend %extend_headline;
  font-size: 2rem;
  &.post-title {
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 15px;
  }
}

h2 {
  @extend %extend_headline;
  font-size: 1.5rem;
  &.post-title {
    font-size: 1.5rem;
  }
}

h3 {
  @extend %extend_headline;
  font-size: 1.125rem;
}

h4 {
  @extend %extend_headline;
  letter-spacing: 0;
  font-size: 1rem;
}

h5 {
  @extend %extend_headline;
  letter-spacing: 0;
  font-size: .75rem;
}

h6 {
  @extend %extend_headline;
  letter-spacing: 0;
  font-size: .625rem;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  color: $black;
  text-decoration: none;
  &:hover {
    color: $font_color;
    text-decoration: none;
  }
}

code {
  color: #657580;
  background-color: transparent;
}
