.post-content {
	font-size: 1.125rem;
	h1 {
		margin-bottom: 1.2rem;
	}
	h2 {
		margin-bottom: 1.2rem;
	}
	h3 {
		margin-bottom: 1.2rem;
	}
	h4 {
		margin-bottom: 1.2rem;
	}
	h5 {
		margin-bottom: 1.2rem;
	}
	h6 {
		margin-bottom: 1.2rem;
	}
}

.page-title {
	padding-bottom: 10px;
	margin-top: 0;
	margin-bottom: 2rem;
	border-bottom: 2px solid $black;
}

p {
	margin: 1.5em 0;
}

ul {
	margin-bottom: 1.5em;
}

ol {
	margin-bottom: 1.5em;
}

blockquote {
	display: block;
	background: $white;
	padding: 5px 20px 15px 50px;
	margin: 0 0 20px;
	position: relative;
	font-family: $font_8, $font_9;
	font-size: 1.125rem;
	font-style: italic;
	border: 0;
	letter-spacing: 0;
	color: $font-color;
}

blockquote::before {
	content: "\201C";
	font-family: $font_8, $font_9;
	font-size: 3.75rem;
	font-weight: bold;
	font-style: normal;
	color: $blue_lighten_1;
	position: absolute;
	left: 0;
	top: -8px;
}

blockquote::after {
	content: "";
}

.read-more {
	display: inline-block;
	font-size: 14px;
	font-family: $font_1, $font_2, $font_3, $font_4, $font_5, $font_6;
	font-weight: bold;
	margin-top: 10px;
	&:hover {
		text-decoration: none;
	}
}

#content {
	.post {
		margin-bottom: 35px;
		.feat-strip {
			padding: 3px 5px;
			font-size: .6875rem;
			font-weight: bold;
			letter-spacing: 1px;
			text-transform: uppercase;
			color: $black;
			background-color: $color_saffron_approx;
		}
		.first-post {
			background-size: 0;
			.post-title-wrapper .feat-strip {
				display: none;
			}
		}
		.post-image {
			margin-bottom: 10px;
			position: relative;
			.feat-strip {
				position: absolute;
				top: 5px;
				left: 0;
			}
			.post-tags {
				z-index: 10;
				position: absolute;
				bottom: 5px;
				left: 10px;
				opacity: .3;
			}
			&:hover .post-tags {
				opacity: 1;
			}
		}
		.post-meta {
			font-size: .875rem;
			color: $font-color;
			margin-bottom: 10px;
			a {
				color: $black;
			}
		}
	}
	.post-tags {
		display: inline;
		margin: 0;
		padding: 0;
		list-style-type: none;
		li {
			display: inline-block;
			margin: 0 5px 5px 0;
			padding: 0;
			a {
				display: inline-block;
				height: 24px;
				color: $white;
				padding: 0 10px;
				font-size: .8125rem;
				line-height: 24px;
				text-decoration: none;
				background-color: $primary_color;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 12px;
				//Instead of the line below you could use @include background-clip($clip)
				background-clip: padding-box;
				&:hover {
					background-color: $blue-darken_1;
					color: $white;
				}
			}
		}
	}
	.author-info {
		margin-bottom: 40px;
		padding-bottom: 40px;
		.author-image {
			max-width: 100px;
			height: auto;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			//Instead of the line below you could use @include background-clip($clip)
			background-clip: padding-box;
		}
		.author-name {
			margin-top: 0;
		}
		.author-bio {
			margin-bottom: 0;
		}
	}
	.tag-info {
		.tag-name {
			margin-top: 0;
		}
	}
	.divider-wrapper {
		clear: left;
	}
	.color-divider {
		clear: left;
		height: 2px;
		margin: 0 15px 45px;
	}
}
