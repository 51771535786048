.comment-list {
  list-style: none;
  padding: 0;
  margin: 40px 0 0;
  float: left;
  width: 100%;
  .comment-children {
    list-style: none;
    margin-left: 43px;
  }
  .comment-avatar {
    float: left;
    margin: 0 23px 0 0;
  }
  a.comment-name {
    font-weight: bold;
    display: inline-block;
    margin: 0;
  }
  .comment-message {
    color: $red;
    font-size: 0.8em;
  }
  .comment-date {
    color: $grey;
    text-decoration: none;
    font-size: 0.8em;
  }
  .comment-body {
    margin-bottom: 20px;
    padding: 0 0 20px;
    .comment-content, .comment-parent {
      display: block;
      clear: both;
      margin: 0 0 16px 83px;
      position: relative;
    }
    .comment-parent {
      margin: 0 0 0 83px;
      blockquote {
        font-size: 1rem;
        margin: 0;
      }
    }
  }
  .comment-reply {
    margin: 0 0 0 83px;
    -webkit-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out
  }
}

#comment-form {
  width: 100%;
  margin: 0 0 20px 0;
  float: left;
  .comment-form-author, .comment-form-email, .comment-form-website {
    width: 33.3%;
    float: left;
    padding-right: 20px;
    label {
      width: 100%;
      float: left;
      margin: 0 0 2px;
    }
    input {
      width: 100%;
    }
  }
  .comment-form-website {
    padding-right: 0;
  }
  .comment-form-comment {
    width: 100%;
    float: left;
    margin-bottom: 20px;
    #comment-content {
      width: 100%;
      max-width: 100%;
    }
    #cancel-reply {
      margin-bottom: 20px;
    }
  }
}
