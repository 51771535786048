@import "colors";
@import "base";
@import "modules/header";
@import "modules/post";
@import "modules/buttons";
@import "modules/nav";
@import "modules/widgets";
@import "modules/comments";
@import "modules/footer";

#main {
	background: $white;
	padding: 30px 30px 0 30px;
	#wrapper {
		margin-right: 330px;
		#content {
			float: left;
			width: 100%;
			.pagination {
				position: relative;
				display: block;
				margin: 0 0 45px 0;
				padding: 30px 0;
				font-size: 14px;
				font-family: $font_1, $font_2, $font_3, $font_4, $font_5, $font_6;
				color: $black;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 0;
				//Instead of the line below you could use @include background-clip($clip)
				background-clip: padding-box;
				border-top: 2px solid $black;
				border-bottom: 1px solid $primary_color;
				.page-number {
					float: left;
					width: 30%;
					text-align: left;
				}
				.pagination-links {
					float: right;
					width: 70%;
					text-align: right;
					a {
						font-weight: bold;
						&:hover {
							text-decoration: none;
						}
						&.older-posts {
							margin-left: 15px;
							&.bordered {
								padding-left: 15px;
								border-left: 1px solid $primary_color;
							}
						}
					}
				}
			}
		}
		#sidebar {
			float: right;
			width: 300px;
			margin-right: -330px;
		}
	}
}

#sidebar {
	margin-bottom: 30px;
}

.social-icons {
	margin: 0;
	padding: 0;
	list-style-type: none;
	li {
		float: left;
		margin: 0 5px 5px 0;
		padding: 0;
		&:last-child {
			margin-right: 0;
		}
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}
@media(max-width:767px) {
	#top-bar {
		display: none;
	}
	#content .post {
		clear: left;
	}
}

@media(min-width:768px) {
	.post-template #content .post .post-adjacent .next-post {
		text-align: right;
	}
	#content .post {
		&:nth-of-type(odd) {
			clear: left;
		}
		&:nth-of-type(even) {
			clear: none;
		}
	}
}
.post-comments {
	h2 {
		border-bottom: 2px solid #87a2bd;
	}
}
@font-face {
	font-family: $font_0;
	font-weight: normal;
	font-style: normal;
}
img {
	max-width: 100%;
	height: auto;
}
.mt-0 {
	margin-top: 0;
}
.mt-5 {
	margin-top: 5px;
}
.mt-10 {
	margin-top: 10px;
}
.mt-15 {
	margin-top: 15px;
}
.mt-30 {
	margin-top: 30px;
}
.mb-0 {
	margin-bottom: 0;
}
.mb-5 {
	margin-bottom: 5px;
}
.mb-10 {
	margin-bottom: 10px;
}
.mb-15 {
	margin-bottom: 15px;
}
.mb-30 {
	margin-bottom: 30px;
}
textarea {
	@extend %extend_4;
	&:focus {
		outline: 0;
	}
}
select {
	@extend %extend_4;
	&:focus {
		outline: 0;
	}
}
table {
	margin-bottom: 1.5em;
	border-collapse: collapse;
	border-spacing: 0;
	empty-cells: show;
	border: 1px solid $primary_color;
	tr {
		th {
			@extend %extend_5;
			&:first-child {
				border-left-width: 0;
			}
		}
		td {
			@extend %extend_5;
			&:first-child {
				border-left-width: 0;
			}
		}
		&:nth-child(odd) td {
		}
	}
}

.post-template #content .post {
	.post-image {
		float: none;
		width: 100%;
		margin: 15px 0 25px;
	}
	.post-tags {
		display: block;
		margin-top: 5px;
		margin-bottom: 10px;
	}
	.post-content {
		margin-bottom: 30px;
		> *:last-child {
			margin-bottom: 0;
		}
	}
	.post-share {
		margin-bottom: 30px;
	}
	.post-adjacent {
		border-top: 1px solid $primary_color;
		padding-top: 30px;
		margin-bottom: 30px;
		strong {
			font-size: .875rem;
		}
		.post-title {
			margin: 5px 0 10px;
		}
	}
	.post-related {
		margin-bottom: 30px;
	}
}
input:not([type=radio]):not([type=checkbox]):not([type=button]):not([type=reset]):not([type=submit]) {
	@extend %extend_4;
	&:focus {
		outline: 0;
	}
}

@media(min-width:1360px) {
	.container {
		width: 1290px;
	}
}
@media(max-width:991px) {
	#top-bar {
		text-align: center;
		ul li {
			float: none;
			display: inline-block;
		}
	}
	#nav {
		text-align: center;
		#mobile-nav-trigger {
			display: block;
			padding: 0 15px;
			font-family: $font_1, $font_2, $font_3, $font_4, $font_5, $font_6;
			font-size: .875rem;
			line-height: 2.8125rem;
			text-decoration: none;
			text-transform: uppercase;
			&:hover {
				color: $white;
			}
		}
		ul.nav {
			display: none;
			li {
				float: none;
				width: 100%;
				border-right: 0;
				a {
					width: 100%;
				}
			}
		}
	}
	#content {
		.author-info {
			text-align: center;
			.author-image {
				margin-bottom: 15px;
			}
		}
		.tag-info {
			text-align: center;
		}
		.divider-wrapper {
			clear: none;
		}
	}
	#header #top #logo {
		float: none;
		width: 100%;
		text-align: center;
	}
	#main #wrapper {
		margin-right: 0;
		#content {
			float: none;
		}
		#sidebar {
			float: none;
			width: 100%;
			margin-right: 0;
		}
	}
	#footer > .container .footer-wrapper {
		font-size: .875rem;
		text-align: center;
		.footer-logo {
			display: none;
		}
		.nav li {
			margin: 0 15px 5px;
		}
	}
}
@media(max-width:480px) {
	#header #top #logo img {
		max-width: 150px;
	}
}
@media(min-width:992px) {
	#header #top #top-ad {
		img {
			max-width: 468px;
		}
		.adsbygoogle {
			width: 468px;
			height: 60px;
		}
	}
}
@media(min-width:1200px) {
	#content {
		.post .first-post {
			height: 500px;
			position: relative;
			background-size: cover;
			background-position: center;
			margin-bottom: 15px;
			.post-title-wrapper {
				position: absolute;
				z-index: 10;
				width: 100%;
				bottom: 0;
				left: 0;
				padding: 80px 30px 30px;
				color: $white;
				background-color: rgba(0,0,0,0) 0;
				.feat-strip {
					display: inline-block;
				}
				.post-title {
					font-size: 2rem;
					a {
						color: $white;
					}
				}
				.post-meta {
					margin-bottom: 0;
					color: $white;
					a {
						color: $white;
						font-weight: bold;
					}
				}
			}
			.post-image {
				position: absolute;
				width: auto;
				top: 30px;
				left: 30px;
				.feat-strip {
					display: none;
				}
				.post-image-link {
					display: none;
				}
				.post-tags {
					display: inline-block;
					width: auto;
					top: 0;
					left: 0;
					position: relative;
				}
			}
			+ .post-excerpt {
				font-size: 1.125rem;
			}
			&:hover .post-tags {
				opacity: 1;
			}
		}
		&.content-home .post {
			&:nth-of-type(odd) {
				clear: none;
			}
			&:nth-of-type(3n + 1) {
				clear: left;
			}
		}
		&.content-2-col .post {
			&:nth-of-type(odd) {
				clear: left;
			}
			&:nth-of-type(even) {
				clear: none;
			}
		}
		&.content-3-col .post {
			clear: none;
			&:nth-of-type(3n + 1) {
				clear: left;
			}
		}
	}
	#header #top #top-ad {
		img {
			max-width: 728px;
		}
		.adsbygoogle {
			width: 728px;
			height: 90px;
		}
	}
}
/*# sourceMappingURL=screen.css.map */
