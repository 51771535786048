.widget {
	margin-top: 45px;
	&:first-of-type {
		margin-top: 0;
	}
	.widget-title {
		padding-bottom: 10px;
		margin-bottom: 0;
		border-bottom: 2px solid $primary_color;
	}
	.widget-list {
		margin: 0;
		padding: 0;
		list-style-type: none;
		li {
			padding: 10px 0;
			border-bottom: 1px solid $primary_color;
			&:last-child {
				border-bottom: 0;
			}
		}
	}
	.widget-content {
		margin-top: 15px;
		margin-bottom: 15px;
	}
	&#widget-twitter {
		.tweet {
			margin-bottom: 10px !important;
			a {
				&:hover {
				}
			}
		}
		.timePosted {
			margin-bottom: 0;
			font-size: .875rem;
			a {
			}
		}
		.twitter-info {
			border-top: 1px solid $primary_color;
			padding: 15px 0;
		}
	}
	&#widget-weather {
		i {
			color: $white;
			font-family: $font_0;
			font-size: 9.375rem;
			font-weight: normal;
			font-style: normal;
			line-height: 1;
			//Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			text-shadow: 0 1px 3px $black_15;
		}
		p {
			margin: 0;
			&.weather-temp {
				font-size: 3.75rem;
				font-weight: 700;
			}
		}
		.currently {
			margin: 0 20px;
		}
		.icon-0:before {
			content: ":";
		}
		.icon-1:before {
			content: "p";
		}
		.icon-2:before {
			content: "S";
		}
		.icon-3:before {
			content: "Q";
		}
		.icon-4:before {
			content: "S";
		}
		.icon-5:before {
			content: "W";
		}
		.icon-6:before {
			content: "W";
		}
		.icon-7:before {
			content: "W";
		}
		.icon-8:before {
			content: "W";
		}
		.icon-9:before {
			content: "I";
		}
		.icon-10:before {
			content: "W";
		}
		.icon-11:before {
			content: "I";
		}
		.icon-12:before {
			content: "I";
		}
		.icon-13:before {
			content: "I";
		}
		.icon-14:before {
			content: "I";
		}
		.icon-15:before {
			content: "W";
		}
		.icon-16:before {
			content: "I";
		}
		.icon-17:before {
			content: "W";
		}
		.icon-18:before {
			content: "U";
		}
		.icon-19:before {
			content: "Z";
		}
		.icon-20:before {
			content: "Z";
		}
		.icon-21:before {
			content: "Z";
		}
		.icon-22:before {
			content: "Z";
		}
		.icon-23:before {
			content: "Z";
		}
		.icon-24:before {
			content: "E";
		}
		.icon-25:before {
			content: "E";
		}
		.icon-26:before {
			content: "3";
		}
		.icon-27:before {
			content: "a";
		}
		.icon-28:before {
			content: "A";
		}
		.icon-29:before {
			content: "a";
		}
		.icon-30:before {
			content: "A";
		}
		.icon-31:before {
			content: "6";
		}
		.icon-32:before {
			content: "1";
		}
		.icon-33:before {
			content: "6";
		}
		.icon-34:before {
			content: "1";
		}
		.icon-35:before {
			content: "W";
		}
		.icon-36:before {
			content: "1";
		}
		.icon-37:before {
			content: "S";
		}
		.icon-38:before {
			content: "S";
		}
		.icon-39:before {
			content: "S";
		}
		.icon-40:before {
			content: "M";
		}
		.icon-41:before {
			content: "W";
		}
		.icon-42:before {
			content: "I";
		}
		.icon-43:before {
			content: "W";
		}
		.icon-44:before {
			content: "a";
		}
		.icon-45:before {
			content: "S";
		}
		.icon-46:before {
			content: "U";
		}
		.icon-47:before {
			content: "S";
		}
	}
	a:not(.button) {
		color: $black;
		overflow-wrap: break-word;
		word-wrap: break-word;
		//Instead of the line below you could use @include word-break($value)
		word-break: break-all;
		//Instead of the line below you could use @include word-break($value)
		word-break: break-word;
		&:hover {
			color: $font_color;
		}
	}
	&#widget-social .button {
		width: 100%;
		margin-bottom: 5px;
	}
	&#widget-newsletter #email {
		width: 100%;
		margin-bottom: 10px;
	}
}

.tagcloud {
	.tag {
		font-size: 0.875rem;
		margin-right: 5px;
		margin-bottom: 5px;
	}
}
