#footer {
	background-color: $black;
}

#footer > .container {
	background-color: $black;
	.footer-wrapper {
		padding: 30px 15px;
		color: $white;
		.nav {
			margin: 0;
			padding: 0;
			list-style-type: none;
			li {
				display: inline-block;
				margin: 0 15px 5px 0;
				a {
					color: $white;
					padding: 0;
					&:hover {
						background: none;
						text-decoration: underline;
					}
				}
				&.nav-current {
					font-weight: bold;
				}
			}
		}
		.copyright {
			width: 100%;
			margin-top: 10px;
			padding-top: 15px;
		}
	}
	> .back-top {
		margin: 0 -15px;
		text-align: center;
		border-top: 1px solid $white;
		background-color: $white;
		a {
			font-size: 14px;
			font-weight: bold;
			color: $black;
			line-height: 40px;
			text-decoration: none;
			text-transform: uppercase;
			opacity: .5;
			&:hover {
				opacity: 1;
			}
		}
	}
}
